import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const PolitikaPrivatnosti = () => {

    return (
        <Layout>
            <SEO title='Politika Privatnosti' />
            <h2>Politika privatnosti</h2>
            <p>Udruženje “RAZVOJ UE” čuva privatnost svih posetilaca sajta vodiczastare.rs  i štiti lične podatke posetilaca sajta. Podatke ostavljene prilikom slanja mail-a ili popunjavanjem formulara na sajtu u Kontakt formi, Udruženje “RAZVOJ UE”  koje je osnivač vodiczastare.rs neće davati na uvid trećem licu.</p>
            <p>Važno je istaći da ovaj sajt možete posetiti bez otkrivanja bilo kakve informacije o sebi. Naši serveri sakupljaju imena domena, ali ne i e-mail adrese posetilaca sajta. Na pojedinim delovima našeg sajta možemo zatražiti lične informacije o vama kako bismo vam omogućili ostvarivanje određenih prava.</p>
            <h4>Upotreba sakupljenih informacija</h4>
            <p>Informacije koje automatski prikupljamo predstavljaju statističke podatke, a  imena domena do kojih dođemo ne koristimo kako bismo vas lično identifikovali, već da bi na osnovu te i drugih informacija izmerili broj poseta sajtu, prosečno vreme provedeno na sajtu, pregledane stranice, vasa interesovanja itd. Ove informacije ćemo koristiti isključivo da bismo izmerili frekvenciju posećivanja našeg sajta i unapredili njegov sadržaj.</p>
            <p>Ukoliko ste maloletni, neophodno je da dobijete dozvolu svojih roditelja ili staratelja pre nego što nam date informacije o sebi.</p>
        </Layout>
    );
};

export default PolitikaPrivatnosti;